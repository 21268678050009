const Mylogo = ({ width }) => {
    return (
        <div className='MyLogo'>
            <svg className='my_logo' data-name="Logo SVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.42 383.66" width={width}>
                <defs><style>
                {'.logo-1,.logo-2{fill:#fff;}'}
                {'.logo-1{opacity:0.2;}'}
                </style></defs>
                <path className="logo-1" d="M1030.83,214.3a105.62,105.62,0,1,0,105.62,105.62A105.62,105.62,0,0,0,1030.83,214.3Zm0,172.42a66.8,66.8,0,1,1,66.8-66.8A66.8,66.8,0,0,1,1030.83,386.72Z" />
                <path className="logo-1" d="M1174.65,268.15a105.62,105.62,0,1,0,105.62,105.62A105.62,105.62,0,0,0,1174.65,268.15Zm0,172.42a66.81,66.81,0,1,1,66.8-66.8A66.8,66.8,0,0,1,1174.65,440.57Z" />
                <path className="logo-1" d="M1296,214.3a105.62,105.62,0,1,0,105.62,105.62A105.62,105.62,0,0,0,1296,214.3Zm0,172.42a66.8,66.8,0,1,1,66.8-66.8A66.8,66.8,0,0,1,1296,386.72Z"/>
                <path className="logo-1" d="M1174.65,95.73a105.62,105.62,0,1,0,105.62,105.61A105.61,105.61,0,0,0,1174.65,95.73Zm0,172.42a66.81,66.81,0,1,1,66.8-66.81A66.81,66.81,0,0,1,1174.65,268.15Z"/>
                <path className="logo-2" d="M1296.59,214.31h-19.93v1.8h0V256h0V360.81h38.7V256a66.69,66.69,0,0,1,33.4,104.81h44.66a105.67,105.67,0,0,0-96.83-146.5Z"/>
                <path className="logo-2" d="M1235.66,287.56a105.6,105.6,0,0,0,43.79-73.26h-39.28a66.9,66.9,0,0,1-46.17,51v-51h-38.7V360.81H1194v-51a66.87,66.87,0,0,1,46.17,51h39.28A105.56,105.56,0,0,0,1235.66,287.56Z"/>
                <path className="logo-2" d="M1097.75,214.3v23.91a105.15,105.15,0,0,0-59.47-23.65h0c-.32,0-.65,0-1,0-1.06-.07-2.12-.13-3.19-.16s-2.19-.06-3.29-.06a105.43,105.43,0,0,0-19.35,1.78v0h0V256h0V360.81h38.7V256a66.71,66.71,0,0,1,33.39,104.84h52.88V214.3Z"/>
            </svg>
        </div>
    )
}

export default Mylogo
