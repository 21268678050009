import React from 'react'

const moneyUpIcon = ({width, height}) => {
    return (
        <div>
           <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
               <path className="cls-1" d="M18.5 15c-2.485 0-4.5 2.015-4.5 4.5s2.015 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.015-4.5-4.5-4.5zm0 2l2.5 3h-2v2h-1v-2h-2l2.5-3zm-6.346 3.894c-.918.506-1.996.839-3.154 1.001v2.105h-2v-1.996c-2.069-.037-4.213-.53-6-1.453l.909-3.289c1.912.742 4.457 1.53 6.45 1.079 2.299-.521 2.769-2.885.229-4.021-1.863-.867-7.557-1.611-7.557-6.487 0-2.725 2.078-5.165 5.969-5.7v-2.133h2v2.036c1.447.038 3.071.29 4.885.841l-.725 3.295c-1.535-.539-3.232-1.029-4.885-.929-2.979.174-3.24 2.752-1.162 3.832 2.854 1.342 6.425 2.401 7.534 5.203-2.025 1.497-3.058 4.039-2.493 6.616z"/>
            </svg> 
        </div>
    )
}

export default moneyUpIcon
