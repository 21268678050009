import React from 'react'

const recordIcon = ({width, height}) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
                <path className="cls-1" d="M11.917 11.019c0-.507-.41-.918-.916-.918s-.917.411-.917.918c0 .507.411.918.917.918s.916-.411.916-.918m1.751 0c0 1.473-1.196 2.671-2.667 2.671-1.47 0-2.667-1.198-2.667-2.671 0-1.473 1.197-2.671 2.667-2.671 1.471 0 2.667 1.198 2.667 2.671m5.125-2.679c-.827-2.397-2.722-4.29-5.117-5.113l-.118.936c1.981.741 3.553 2.313 4.299 4.293l.936-.116zm-1.858.232c-.652-1.58-1.913-2.843-3.491-3.494l-.12.955c1.166.548 2.109 1.491 2.656 2.659l.955-.12zm-2.267 2.447c0-2.028-1.643-3.673-3.667-3.673-2.025 0-3.667 1.645-3.667 3.673s1.642 3.673 3.667 3.673c2.024 0 3.667-1.645 3.667-3.673m-5.991 4.987c-1.166-.549-2.107-1.492-2.654-2.66l-.954.119c.65 1.582 1.911 2.844 3.49 3.496l.118-.955zm-.238 1.906c-1.989-.747-3.569-2.332-4.308-4.329l-.935.118c.822 2.412 2.721 4.318 5.126 5.147l.117-.936zm13.561-6.893c0 .264-.022.521-.04.78-.132-.033-.457-.114-.894-.021-.295-.486-.85-.799-1.503-.799-.685 0-1.27.351-1.548.885-.946-.17-2.098.418-2.098 1.593v2.761c-.687-.72-2.916-.376-2.916 1.41 0 .275.062.549.185.82.066.158 1.393 2.805 1.467 2.955-1.144.404-2.37.635-3.652.635-6.075 0-11.001-4.933-11.001-11.019 0-6.085 4.926-11.019 11-11.019s11 4.934 11 11.019m-6.302 6.286c.007.01.757 1.39.872 1.607.124.228.494.179.494-.12v-5.335c0-.839 1.348-.814 1.348 0v4.311c0 .234.453.23.453 0l.002-5.131c0-.441.355-.656.714-.656.363 0 .729.221.729.656v5.072c0 .235.437.244.437.006v-4.323c0-.862 1.475-.886 1.475 0v4.579c0 .233.472.234.472 0v-2.849c0-.778 1.304-.822 1.304.039l.002 6.499c0 1.489-.831 2.34-2.406 2.34h-2.935c-1.497 0-2.022-.846-2.438-1.696-.395-.808-2.001-3.976-2.125-4.272-.066-.144-.095-.28-.095-.404 0-.809 1.276-1.128 1.697-.323"/>
            </svg>
        </div>
    )
}

export default recordIcon
